jQuery(document).ready(function () {
    jQuery('.owl-carousel').owlCarousel({
        items: 1,
        mouseDrag: false,
        dots: false,
        loop: true,
        autoplay: true,
        autoplaySpeed: 1000,
        autoplayTimeout: 6000
    });

    jQuery(".menu-toggle").click(function () {
        jQuery('body').toggleClass("menu-open");
    });

    if (jQuery(window).width() > 767 && jQuery(window).width() < 1025) {
        jQuery('.touch .menu > li.menu-item-has-children > a').on("click touchstart", function (event) {
            if (!jQuery(this).parent().hasClass("hover")) {
                event.preventDefault();
                event.stopPropagation();
                jQuery(this).parent().addClass("hover").siblings().removeClass("hover");
            }
        });
    }


    heightGlobal();
});

jQuery(window).resize(function () {
    heightGlobal();
});

jQuery(window).load(function () {
    heightGlobal();
});

function heightGlobal() {
    equalheight('.service-section .col-md-3 .service-item');
}

/*===== Equal Height =======*/
equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = new Array(),
            jQueryel,
            topPosition = 0;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight('auto')
            topPostion = jQueryel.offset().top;
            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].innerHeight(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = jQueryel.innerHeight();
                rowDivs.push(jQueryel);
            } else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height('auto');
    }
}
